

















































































































































































































































































































































































































































.addClassCtr {
  .el-form-liveClass {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-period {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-select {
        flex: 1;
      }
    }
  }
}
